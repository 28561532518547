import {SouhlasPriloha, SouhlasSubjekt} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import {isBefore, isFuture, isValid, subDays} from 'date-fns';
import {FormattedMessage} from 'react-intl';
import * as yup from 'yup';
import {Zahranici} from '../types';
import {validateDate} from './CommonUtils';

// Common setup validations
yup.setLocale({
    mixed: {
        required: () => <FormattedMessage id="yup.required" />,
    },
    string: {
        max: ({max}) => <FormattedMessage id="yup.max" values={{znak: max, pismeno: max === 1 ? '' : max > 1 && max < 5 ? 'y' : 'ů'}} />,
        min: ({min}) => <FormattedMessage id="yup.min" values={{znak: min, pismeno: min === 1 ? '' : min > 1 && min < 5 ? 'y' : 'ů'}} />,
        email: () => <FormattedMessage id="yup.email.format" />,
    },
    number: {
        max: ({max}) => <FormattedMessage id="yup.max" values={{znak: max, pismeno: max === 1 ? '' : max > 1 && max < 5 ? 'y' : 'ů'}} />,
        min: ({min}) => <FormattedMessage id="yup.min" values={{znak: min, pismeno: min === 1 ? '' : min > 1 && min < 5 ? 'y' : 'ů'}} />,
    },
    array: {
        min: () => <FormattedMessage id="yup.required" />,
    },
});

const string = yup.string();

const zahraniciRegex = /^(?![ ./'-])(?!\d+$)(?!.*([ ./'-])\1{1})(?:[a-zA-Zá-žÁ-Ž]*[0-9a-zA-Zá-žÁ-Ž ./'-]*[0-9a-zA-Zá-žÁ-Ž])?$/;
const zahraniciRegexWithOnlyNumbers = /^(?![ ./'-])(?!.*([ ./'-])\1{1})(?:[a-zA-Zá-žÁ-Ž]*[0-9a-zA-Zá-žÁ-Ž ./'-]*[0-9a-zA-Zá-žÁ-Ž])?$/;

const validateDatum = ({
    date,
    validFuture,
    validPast,
    onlyValidate,
}: {
    date: string;
    validFuture?: boolean | undefined;
    validPast?: boolean | undefined;
    onlyValidate?: boolean | undefined;
}) => {
    const value = date?.split('.').reverse().join('-');
    const isValidDatum = validateDate(value);
    const isFutureDatum = isFuture(new Date(value));
    const isPastDatum = isBefore(new Date(value), subDays(new Date(), 1));

    if (onlyValidate) {
        if (!(isValidDatum && isValid(new Date(value))) || value.length <= 7) {
            return {message: <FormattedMessage id="yup.datum.invalid" />};
        }
        return undefined;
    }
    if (!(isValidDatum && isValid(new Date(value))) || value.length <= 7) {
        return {message: <FormattedMessage id="yup.datum.invalid" />};
    }
    if (isFutureDatum && validFuture) {
        return {message: <FormattedMessage id="yup.datum.budoucnost" />};
    }

    if (isPastDatum && validPast) {
        return {message: <FormattedMessage id="yup.datum.minulost" />};
    }

    return undefined;
};

const datumNotPast = ({onlyValidate}: {onlyValidate?: boolean}) =>
    string
        .test(
            '',
            ({value}) => validateDatum({date: value, validPast: true, onlyValidate})?.message,
            (value) => {
                if (value) {
                    return !validateDatum({date: value, validPast: true, onlyValidate});
                }
                return true;
            },
        )
        .typeError('Pole je povinné');

const validateCisloStanoviska = (cisloStanoviska = '') => {
    if (cisloStanoviska?.length === 0) {
        return string.required();
    }

    if (/16|26/.test(cisloStanoviska)) {
        return string.min(8).max(8).required();
    }
    if (!/16|26/.test(cisloStanoviska)) {
        return string.test(
            '',
            () => <FormattedMessage id="yup.cisloStanoviska" />,
            () => {
                return false;
            },
        );
    }

    if (!/^\d+$/.test(cisloStanoviska)) {
        return string.test(
            '',
            () => <FormattedMessage id="yup.cisloStanoviska" />,
            () => {
                return false;
            },
        );
    }

    return string.required();
};

// BU validace
const GENVAL015 = string
    .nullable()
    .max(8)
    .matches(/^\d{8}$/, () => <FormattedMessage id="yup.number.ico" />);
const GENVAL051 = string
    .nullable()
    .max(10)
    .matches(/^[0-9]*$/, () => <FormattedMessage id="yup.number.only.cisloPopisne" />);

const obec = string.nullable().required().max(70);
const obecZahranici = string
    .nullable()
    .required()
    .min(2)
    .max(40)
    .matches(zahraniciRegex, () => <FormattedMessage id="yup.zahranici" />);
const psc = string.nullable().required().max(10);
const pscZahranici = string
    .nullable()
    .required()
    .min(1)
    .max(10)
    .matches(zahraniciRegexWithOnlyNumbers, () => <FormattedMessage id="yup.zahranici" />);
const ulice = string.nullable().max(60);
const uliceZahranici = string
    .nullable()
    .min(2)
    .max(60)
    .matches(zahraniciRegex, () => <FormattedMessage id="yup.zahranici" />);
const mistniCast = string.nullable().max(40);
const cisloPopisne = GENVAL051;
const cisloPopisneZahranici = string
    .nullable()
    .min(1)
    .max(10)
    .matches(zahraniciRegexWithOnlyNumbers, () => <FormattedMessage id="yup.zahranici" />);
const cisloOrientaci = string.nullable().max(10);
const cisloEvidencni = string.nullable().max(10);
const stat = string.nullable().max(60);
const adresa = yup.object().nullable().shape({
    ulice,
    psc,
    obec,
    mistniCast,
    cisloPopisne,
    cisloOrientaci,
    cisloEvidencni,
});
const adresaZahranici = yup.object().nullable().shape({
    ulice: uliceZahranici.required(),
    psc: pscZahranici.required(),
    obec: obecZahranici.required(),
    cisloPopisne: cisloPopisneZahranici.required(),
    stat: stat.required(),
});

const jmeno = string
    .max(70)
    .trim(() => <FormattedMessage id="yup.string.whitespace" values={{pole: 'Jméno'}} />)
    .matches(/^(?!\d+$)(?!.*([ '-])\1{1})(?:[a-zA-Zá-žÁ-Ž]*[a-zA-Zá-žÁ-Ž '-]*[a-zA-Zá-žÁ-Ž])?$/, () => (
        <FormattedMessage id="yup.string.only" values={{pole: 'Jméno'}} />
    ));

const prijmeni = string
    .max(70)
    .trim(() => <FormattedMessage id="yup.string.whitespace" values={{pole: 'Příjmení'}} />)
    .matches(/^(?!\d+$)(?!.*([ '-])\1{1})(?:[a-zA-Zá-žÁ-Ž]*[a-zA-Zá-žÁ-Ž '-]*[a-zA-Zá-žÁ-Ž])?$/, () => (
        <FormattedMessage id="yup.string.only" values={{pole: 'Příjmení'}} />
    ));

const verifyICO = <T extends string | null | undefined>(ico: T) => {
    const icoArray = ico?.split('').map(Number);
    const icoArraySum = icoArray?.reduce((a, b, i) => (i < 7 ? a + b * (8 - i) : a), 0) ?? 0;
    const result = icoArraySum % 11;
    let cislo = 0;
    if (result === 0) {
        cislo = 1;
    } else if (result === 1) {
        cislo = 0;
    } else {
        cislo = 11 - result;
    }

    return cislo === icoArray?.[7];
};

const ic = GENVAL015.test(
    '',
    () => <FormattedMessage id="yup.number.ico.no.valid" />,
    (value) => {
        return verifyICO(value);
    },
);

const nazevFirmy = string.max(140);

const email = string
    .email()
    .min(6)
    .max(254)
    .matches(
        /^[\w](?!.*([.])\1{1})(?:[a-zA-Z0-9]*[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*[a-zA-Zá-žÁ-Ž0-9]){0,1}\@[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*){0,1}\.[a-zA-Z0-9]{2,24}$/,
        () => <FormattedMessage id="yup.email.format" />,
    );

const telefonCZUNI = string.matches(/^(\+420|00420)??[0-9][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.cz.uni" />);
const telefonSKUNI = string.matches(/^(\+421|00421)??[0-9][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.sk.uni" />);
const validateTelefon = (telefon = '', required?: boolean) => {
    if (telefon?.length === 0 && required) {
        return string.required();
    }

    if (telefon?.length === 0 && !required) {
        return string.nullable();
    }

    if (/00420|\+420/.test(telefon)) {
        return telefonCZUNI;
    }
    if (/00421|\+421/.test(telefon)) {
        return telefonSKUNI;
    }

    // Predvolba je nepovinna
    // if (/^\d+$/.test(telefon)) {
    //     return number.test(
    //         '',
    //         () => 'Předvolba je povinná',
    //         () => {
    //             return false;
    //         },
    //     );
    // }

    if (!/^\d+$/.test(telefon) || telefon.length < 9 || telefon.length > 9) {
        return string.test(
            '',
            () => <FormattedMessage id="yup.phone.not.correct" />,
            () => {
                return false;
            },
        );
    }

    return required ? string.required() : string.nullable();
};
export const validationSchemaStepOne = yup.object().shape({
    cisloStanoviska: yup.lazy((value) => validateCisloStanoviska(value)),
    datumPlatnosti: datumNotPast({onlyValidate: false}).required(),
    obec: obecZahranici
        .required()
        .max(70)
        .test((value, ctx) => {
            const min = 2;
            const valueLength = value?.length ?? 0;
            const ES = value.toLowerCase() === 'eš';
            const AS = value.toLowerCase() === 'aš';
            if (!(ES || AS) && valueLength < min) {
                return ctx.createError({
                    message: () => <FormattedMessage id="yup.min" values={{znak: min, pismeno: 'y'}} />,
                });
            }
            return true;
        }),
});
export const validationSchemaStepTwo = yup.object().shape({
    zadatel: yup.object().shape({
        subjekt: string.required(),
        jmeno: jmeno.required(),
        prijmeni: prijmeni.required(),
        email: email.required(),
        telefon: yup.lazy((value) => validateTelefon(value, true)),
        nazevFirmy: nazevFirmy.when('subjekt', {
            is: SouhlasSubjekt.PRAVNICKA_OSOBA,
            then: (schema) => schema.required(),
            otherwise: () => string.nullable(),
        }),
        ico: ic.when('subjekt', {
            is: SouhlasSubjekt.PRAVNICKA_OSOBA,
            then: (schema) =>
                schema.test(
                    '',
                    () => <FormattedMessage id="yup.ico.error" />,
                    (value, ctx) => {
                        if (value && ctx.parent.oldIco?.length === 8) {
                            return ctx.parent.oldIco === value;
                        }
                        return true;
                    },
                ),
            otherwise: () => string.nullable(),
        }),

        adresa: adresa.when('zahranici', {
            is: Zahranici.NE,
            then: () => yup.object().required(),
            otherwise: () => adresaZahranici.required(),
        }),
    }),
    investor: yup.object().when('zadatelJeInvestor', {
        is: false,
        then: (schema) =>
            schema.shape({
                subjekt: string.required(),
                jmeno: jmeno.required(),
                prijmeni: prijmeni.required(),
                email: email.required(),
                telefon: yup.lazy((value) => validateTelefon(value, true)),
                nazevFirmy: nazevFirmy.when('subjekt', {
                    is: SouhlasSubjekt.PRAVNICKA_OSOBA,
                    then: (schema) => schema.required(),
                    otherwise: () => string.nullable(),
                }),
                ico: ic.when('subjekt', {
                    is: SouhlasSubjekt.PRAVNICKA_OSOBA,
                    then: (schema) =>
                        schema.test(
                            '',
                            () => <FormattedMessage id="yup.ico.error" />,
                            (value, ctx) => {
                                if (value && ctx.parent.oldIco?.length === 8) {
                                    return ctx.parent.oldIco === value;
                                }
                                return true;
                            },
                        ),
                    otherwise: () => string.nullable(),
                }),

                adresa: adresa.when('zahranici', {
                    is: Zahranici.NE,
                    then: () => yup.object().required(),
                    otherwise: () => adresaZahranici.required(),
                }),
            }),
        otherwise: () => yup.object().nullable(),
    }),
});
export const validationSchemaStepThree = (archivovane: SouhlasPriloha[]) =>
    yup.object().shape({
        udajeOStavbeNazev: string.nonNullable().trim().max(230).required(),
        udajeOStavbePopis: string.nonNullable().trim().max(10_000).required(),
        elektrina: string.when('plyn', {
            is: 'PLYN',
            then: (schema) => schema.nullable(),
            otherwise: (schema) => schema.required(),
        }),
        plyn: string.test(
            '',
            () => <FormattedMessage id="yup.required" />,
            (value, ctx) => {
                if (ctx.parent) {
                    return value === 'PLYN' || ctx.parent.elektrina === 'ELEKTRINA';
                }
                return true;
            },
        ),
        prilohy:
            archivovane.length === 0
                ? yup
                      .array()
                      .min(1)
                      .nullable()
                      .required(() => <FormattedMessage id="yup.required.file" />)
                : yup.array().nullable(),
    });
