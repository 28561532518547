import {FileAllowedTypeImport} from '../../constants';
import {isNotNullOrUndefinedOrEmpty, isNullOrUndefinedOrEmpty} from '../../utils/CommonUtils';

// Povolené formáty příloh
const FileMimeTypeExtensionMap = [
    {ext: 'doc', mime: 'application/msword'},
    {ext: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
    {ext: 'xls', mime: 'application/vnd.ms-excel'},
    {ext: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
    {ext: 'pdf', mime: 'application/pdf'},
    {ext: 'csv', mime: 'text/csv'},
    {ext: 'xml', mime: 'application/xml'},
    {ext: 'xml', mime: 'text/xml'},
    {ext: 'jpg', mime: 'image/jpeg'},
    {ext: 'jpeg', mime: 'image/jpeg'},
    {ext: 'gif', mime: 'image/gif'},
    {ext: 'png', mime: 'image/png'},
    {ext: 'text', mime: 'text/plain'},
    {ext: 'msg', mime: 'application/vnd.ms-outlook'},
];

/**
 * Získá extension přímo z názvu souboru.
 */
export const getFilenameExtension = (filename: string | undefined) => {
    if (!isNullOrUndefinedOrEmpty(filename)) {
        const splitted = filename?.split('.');
        if (splitted && splitted?.length > 1) {
            return splitted[splitted.length - 1];
        }
    }
    return '';
};

export const FileService = {
    getBase64: (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);

            // eslint-disable-next-line functional/immutable-data
            reader.onload = () => {
                const data: string | ArrayBuffer | null = reader.result;
                const codesArray = new Uint8Array(data as ArrayBuffer);
                const arr: string[] = new Array(codesArray.length);
                codesArray.forEach((code, n) => {
                    // eslint-disable-next-line functional/immutable-data
                    arr[n] = String.fromCharCode(code);
                });
                const base64 = btoa(arr.join(''));
                resolve(base64);
            };
            // eslint-disable-next-line functional/immutable-data
            reader.onerror = (error) => reject(error);
        });
    },

    /**
     * Získá extension podle mime type, případně podle filename extension.
     */
    getFileExtension: (file: File): string | undefined => {
        if (isNotNullOrUndefinedOrEmpty(file.type)) {
            return FileService.getFileExtensionByMimeType(file.type);
        }
        return FileService.getFilenameExtension(file.name);
    },

    getAllowedExtensions: (files: File[]): boolean =>
        files.filter((file) => FileAllowedTypeImport.includes(getFilenameExtension(file.name.toLowerCase()) ?? ''))?.length > 0,
    /**
     * Získá extension přímo z názvu souboru.
     */
    getFilenameExtension: (filename: string): string | undefined => getFilenameExtension(filename),

    /**
     * Získa extension podle mime type z mapy. Pokud nenajde protihodnotu, vrací mime zpět.
     */
    getFileExtensionByMimeType: (mime: string) => {
        const extensionMimePair = FileMimeTypeExtensionMap.filter((ext) => ext.mime === mime)[0];
        return extensionMimePair?.ext ? extensionMimePair.ext : mime;
    },
};
